import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BEMHelper from 'react-bem-helper';

const className = new BEMHelper('default-token-expiration-configuration');

const VALID_DURATION_PATTERN = /^([0-9]+)([dmy]){1}$/i;
const DEFAULT_UNIT = 'd';
const DEFAULT_NUMBER = 30;

function getDurationString(number, unit) {
    return `${number}${unit}`;
}

function isValidDuration(duration) {
    const matches = duration.match(VALID_DURATION_PATTERN);

    if (matches && matches.length >= 3) {
        const value = matches[1];
        const unit = matches[2];

        if (value === '0') {
            return { isValid: false, message: 'Enter a value greater than 0.' };
        }

        switch (unit) {
            case 'd':
                return value > 18 * 365
                    ? { isValid: false, message: "Enter a day that's within the next 18 years." }
                    : { isValid: true, message: 'Valid input.' };
            case 'm':
                return value > 18 * 12
                    ? { isValid: false, message: "Enter a month that's within the next 18 years." }
                    : { isValid: true, message: 'Valid input.' };
            case 'y':
                return value > 18
                    ? { isValid: false, message: "Enter a year that's within the next 18 years." }
                    : { isValid: true, message: 'Valid input.' };
            default:
                return { isValid: false, message: 'Invalid unit.' };
        }
    }

    return { isValid: false, message: 'Invalid input.' };
}

export default function DefaultTokenExpirationConfiguration({
    label,
    onChange,
    value,
    additionalInfo,
}) {
    const [number, setNumber] = useState(DEFAULT_NUMBER);
    const [unit, setUnit] = useState(DEFAULT_UNIT);

    useEffect(() => {
        if (value && isValidDuration(value).isValid) {
            const matches = value.match(VALID_DURATION_PATTERN);
            if (matches && matches.length >= 3) {
                setNumber(matches[1]);
                setUnit(matches[2]);
            }
        }
    }, [value]);

    const onChangeNumber = (e) => {
        const newValue = e.target.value;
        const newDurationString = getDurationString(newValue, unit);
        onChange(newDurationString, isValidDuration(newDurationString).isValid);
        setNumber(newValue);
    };

    const onChangeUnit = (e) => {
        const newValue = e.target.value;
        const newDurationString = getDurationString(number, newValue);
        onChange(newDurationString, isValidDuration(newDurationString).isValid);
        setUnit(newValue);
    };

    return (
        <div {...className()}>
            <label>
                <span {...className('input-label')}>{label}</span>
                <input
                    {...className(
                        'duration-number',
                        isValidDuration(value).isValid ? null : 'invalid'
                    )}
                    type="text"
                    name={label}
                    value={number}
                    onChange={onChangeNumber}
                />
                <select className="chart-builder-select" onChange={onChangeUnit} value={unit}>
                    <option value="d">days</option>
                    <option value="m">months</option>
                    <option value="y">years</option>
                </select>
            </label>
            {!isValidDuration(value).isValid && (
                <div {...className('error')}>{isValidDuration(value).message}</div>
            )}
            <div {...className('additional-information')}>{additionalInfo}</div>
        </div>
    );
}

DefaultTokenExpirationConfiguration.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    additionalInfo: PropTypes.string,
};
