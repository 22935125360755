import templateUrl from './crossLinkDefinitionRow.tpl.html';
import externalLinkForm from './externalLinkForm.tpl.html';
import splunkLinkForm from './splunkLinkForm.tpl.html';
import crossLinkForm from './crossLinkForm.tpl.html';
import navigatorLinkForm from './navigatorLinkForm.tpl.html';

import {
    convertMSToString,
    convertStringToMS,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default {
    templateUrl,
    bindings: {
        context: '<',
        editable: '<',
        definition: '<',
        allowedTypes: '<',
        isAdmin: '<',
        restrictTrigger: '<',
        target: '<',
        crossLinkToEdit: '<',
        dashboardContext: '<',
        getAvailableLinkLabels: '<',
        showGlobalLinks: '<',
        onClone: '<',
        onSetDefault: '&',
        onDelete: '&',
        onSave: '&',
        hasDeleteCapability: '<',
        hasUpdateCapability: '<',
        shouldEditableFormFieldForRbac: '<',
        hasCreateCapability: '<',
        showTableActionButton: '<',
        showGlobalDataLinkOption: '<',
    },
    controller: [
        '$scope',
        '$timeout',
        '$element',
        'crossLinkUtils',
        'dimensionService',
        'CROSS_LINK_TYPES',
        'CROSS_LINK_EVENTS',
        'EXTERNAL_LINK_OPTIONS',
        'dashboardSelectorService',
        'confirmService',
        'crossLinkDataService',
        '$log',
        '$q',
        'PRODUCT_NAME',
        'featureEnabled',
        'userAnalytics',
        function (
            $scope,
            $timeout,
            $element,
            crossLinkUtils,
            dimensionService,
            CROSS_LINK_TYPES,
            CROSS_LINK_EVENTS,
            EXTERNAL_LINK_OPTIONS,
            dashboardSelectorService,
            confirmService,
            crossLinkDataService,
            $log,
            $q,
            PRODUCT_NAME,
            featureEnabled,
            userAnalytics
        ) {
            let suggestionGenerationNumber = 0;
            let cloneId = 0;
            let triggerTypes;
            const navigatorDataLinks = featureEnabled('navigatorDataLinks');
            // Trigger Types
            const allTriggerTypes = {
                ANY_METADATA: { label: 'Any metadata value', name: 'ANY_METADATA' },
                ANY_VALUE: { label: 'Any value of', name: 'ANY_VALUE' },
                PAIR: { label: 'Property:Value pair', name: 'PAIR' },
            };

            const viewTypes = ['Instance', 'Aggregate'];

            // Templates & defaults
            const templates = {};
            templates[CROSS_LINK_TYPES.EXTERNAL_LINK] = {
                template: externalLinkForm,
                defaults: {
                    url: '',
                    timeFormat: EXTERNAL_LINK_OPTIONS.defaults.TIME_FORMAT,
                    minimumTimeWindow: EXTERNAL_LINK_OPTIONS.defaults.TIME_WINDOW,
                    propertyKeyMapping: {},
                },
            };
            templates[CROSS_LINK_TYPES.SPLUNK_LINK] = {
                template: splunkLinkForm,
                defaults: {
                    url: '',
                    minimumTimeWindow: EXTERNAL_LINK_OPTIONS.defaults.TIME_WINDOW,
                    propertyKeyMapping: {},
                },
            };
            if (navigatorDataLinks) {
                templates[CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK] = {
                    template: navigatorLinkForm,
                    defaults: {
                        navigatorId: null,
                        navigatorView: null,
                        navigatorName: null,
                        navigatorCode: null,
                    },
                };
            }
            templates[CROSS_LINK_TYPES.INTERNAL_LINK] = {
                template: crossLinkForm,
                defaults: {
                    dashboardId: null,
                    dashboardGroupId: null,
                },
            };
            templates[CROSS_LINK_TYPES.KIBANA_LINK] = {
                template: externalLinkForm,
                defaults: {
                    url: '',
                    timeFormat: EXTERNAL_LINK_OPTIONS.defaults.TIME_FORMAT,
                    minimumTimeWindow: EXTERNAL_LINK_OPTIONS.defaults.TIME_WINDOW,
                    propertyKeyMapping: {},
                },
            };

            // Available Link Types & Metadata
            const linkTypes = {};
            linkTypes[CROSS_LINK_TYPES.EXTERNAL_LINK] = {
                value: CROSS_LINK_TYPES.EXTERNAL_LINK,
                label: 'Custom URL',
                availableTriggers: [
                    allTriggerTypes.ANY_METADATA,
                    allTriggerTypes.ANY_VALUE,
                    allTriggerTypes.PAIR,
                ],
            };
            linkTypes[CROSS_LINK_TYPES.SPLUNK_LINK] = {
                value: CROSS_LINK_TYPES.SPLUNK_LINK,
                label: 'Splunk',
                availableTriggers: [
                    allTriggerTypes.ANY_METADATA,
                    allTriggerTypes.ANY_VALUE,
                    allTriggerTypes.PAIR,
                ],
            };
            if (navigatorDataLinks) {
                linkTypes[CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK] = {
                    value: CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK,
                    label: `${PRODUCT_NAME} navigator`,
                    availableTriggers: [allTriggerTypes.ANY_VALUE, allTriggerTypes.PAIR],
                };
            }
            linkTypes[CROSS_LINK_TYPES.INTERNAL_LINK] = {
                value: CROSS_LINK_TYPES.INTERNAL_LINK,
                label: `${PRODUCT_NAME} dashboard`,
                availableTriggers: [allTriggerTypes.ANY_VALUE, allTriggerTypes.PAIR],
            };
            linkTypes[CROSS_LINK_TYPES.KIBANA_LINK] = {
                value: CROSS_LINK_TYPES.KIBANA_LINK,
                label: 'Kibana',
                availableTriggers: [
                    allTriggerTypes.ANY_METADATA,
                    allTriggerTypes.ANY_VALUE,
                    allTriggerTypes.PAIR,
                ],
            };

            // Available Scopes
            const crosslinkScopes = {
                GLOBAL: {
                    label: 'Entire organization',
                    value: 'GLOBAL',
                },
                DASHBOARD: {
                    label: 'This dashboard',
                    value: 'DASHBOARD',
                },
            };

            const variableSuggestions = [
                {
                    name: 'key',
                    description: 'The first part of a dimension pair.',
                },
                {
                    name: 'value',
                    description: 'The second part of a dimension pair.',
                },
                {
                    name: 'start_time',
                    description:
                        'The beginning of the time window taken from the context where a link appears.',
                },
                {
                    name: 'end_time',
                    description:
                        'The end of the time window taken from the context where a link appears.',
                },
            ];

            const transient = { target: null, definition: null };

            const $ctrl = this;
            const newRbacExperience = featureEnabled('newRbacExperience');

            $ctrl.timeFormats = EXTERNAL_LINK_OPTIONS.TIME_FORMATS;
            $ctrl.timeWindowOptions = EXTERNAL_LINK_OPTIONS.TIME_WINDOW_OPTIONS;
            $ctrl.linkTypes = linkTypes;
            $ctrl.crosslinkScopes = crosslinkScopes;
            $ctrl.transient = transient;
            $ctrl.variableSuggestions = variableSuggestions;
            const {
                ANY_METADATA: {},
                ...triggerTypesForCondition
            } = allTriggerTypes;
            $ctrl.triggerTypesForCondition = triggerTypesForCondition;

            $ctrl.$onInit = $onInit;
            $ctrl.getLinkType = getLinkType;
            $ctrl.getExternalLinkUrlPlaceholder = getExternalLinkUrlPlaceholder;
            $ctrl.getLinkTypeLabel = getLinkTypeLabel;
            $ctrl.updateTargetType = updateTargetType;
            $ctrl.setTriggerType = setTriggerType;
            $ctrl.getTrigger = getTrigger;
            $ctrl.editTrigger = editTrigger;
            $ctrl.getScope = getScope;
            $ctrl.setScope = setScope;
            $ctrl.setTimeWindow = setTimeWindow;
            $ctrl.onSelect = onSelect;
            $ctrl.updateSuggestions = updateSuggestions;
            $ctrl.selectDashboardTarget = selectDashboardTarget;
            $ctrl.isFormValid = isFormValid;
            $ctrl.setTimeFormat = setTimeFormat;
            $ctrl.cancel = cancel;
            $ctrl.save = save;
            $ctrl.rowActions = [];
            $ctrl.insertVariable = insertVariable;
            $ctrl.insertPropertyPrefix = insertPropertyPrefix;
            $ctrl.toggleRow = toggleRow;
            $ctrl.closeParentModal = closeParentModal;
            $ctrl.onAddConditionClick = onAddConditionClick;
            $ctrl.onAddViewType = onAddViewType;
            $ctrl.getViewTypeLabel = getViewTypeLabel;
            $ctrl.onNavigatorSelector = onNavigatorSelector;
            $ctrl.addNewCondition = addNewCondition;
            $ctrl.removeCondition = removeCondition;
            $ctrl.shouldShowAddConditions = shouldShowAddConditions;
            $ctrl.showTableActionButton = false;
            $ctrl.showNavigatorSelector = showNavigatorSelector;

            crossLinkUtils.getGlobalDataLink().then((url) => ($ctrl.globalLinksUrl = '#' + url));
            $ctrl.exitEdit = function () {
                if ($ctrl.editingTrigger) {
                    const splitInput = $ctrl.transientTrigger.split(':');
                    const property = splitInput[0];
                    const value = splitInput.slice(1).join(':');
                    transient.definition.propertyName = property || null;
                    transient.definition.propertyValue = value || null;

                    setEditingTrigger($ctrl.triggerType !== getTriggerType());
                }
            };

            function shouldShowAddConditions() {
                return (
                    !$ctrl.editingTrigger &&
                    ($ctrl.triggerType === $ctrl.triggerTypes.ANY_METADATA ||
                        $ctrl.transient.definition.propertyName)
                );
            }

            function $onInit() {
                $ctrl.viewTypes = viewTypes;
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                const globalDataLinkCheck = newRbacExperience
                    ? $ctrl.showGlobalDataLinkOption
                    : $ctrl.isAdmin;

                if (globalDataLinkCheck) {
                    $ctrl.availableCrosslinkScopes = [
                        crosslinkScopes.DASHBOARD,
                        crosslinkScopes.GLOBAL,
                    ];
                } else {
                    // Non-Admins are not allowed to edit Global cross-links
                    $ctrl.availableCrosslinkScopes = [crosslinkScopes.DASHBOARD];
                }

                // Remove Dashboard scope if we don't have dashboard
                if (!$ctrl.dashboardContext) {
                    delete crosslinkScopes.DASHBOARD;
                }

                // Filter linkTypes based on what we allow
                _.each(linkTypes, (link, key) => {
                    if (!$ctrl.allowedTypes.includes(key)) {
                        delete linkTypes[key];
                    }
                });

                // If we have restricted trigger, re-create triggerTypes available based on crossLinkToEdit
                triggerTypes = $ctrl.restrictTrigger
                    ? getRestrictedTriggerTypes($ctrl.crossLinkToEdit)
                    : allTriggerTypes;
                $ctrl.triggerTypes = triggerTypes;

                // If target is new, open up the row
                if ($ctrl.target._isNew) {
                    $ctrl.rowExpanded = true;
                }

                // Set defaults, where property is missing
                _.each(templates[$ctrl.target.type].defaults, (value, key) => {
                    if (_.isNil($ctrl.target[key])) {
                        $ctrl.target[key] = value;
                    }
                });

                $ctrl.showLinkDropdowns = {
                    linkTypes: _.keys(linkTypes).length > 1,
                    triggerTypes: _.keys(triggerTypes).length > 1,
                    crosslinkScopes: _.keys(crosslinkScopes).length > 1,
                };

                if ($ctrl.context) {
                    const propertySuggestions = [];
                    _.each($ctrl.context, (val, key) => {
                        propertySuggestions.push({
                            name: `properties.[${key}]`,
                            property: key,
                        });
                    });
                    $ctrl.propertySuggestions = propertySuggestions;
                }

                // Flags for showing suggestion boxes for property mapping inputs
                $ctrl.showSignalFxTermsSuggestionBox = false;
                $ctrl.showExternalTermsSuggestionBox = false;
                $ctrl.showSplunkSignalFxTermsSuggestionBox = false;
                $ctrl.showSplunkExternalTermsSuggestionBox = false;

                setTransientTarget();
                setConditions();
                onDefinitionUpdate(transient.definition);
                onTargetUpdate(transient.target);
                setForm(transient.target.type);
                $ctrl.showNavigator = showNavigatorSelector(transient.target.type);

                $ctrl.rowActions = [
                    {
                        label: 'Make default',
                        title: 'Set as default',
                        action: setDefault,
                        enabled: () => {
                            return (
                                !$ctrl.transient.target.isDefault &&
                                !$ctrl.invalidTargetMessage &&
                                ($ctrl.transient.target.type === CROSS_LINK_TYPES.INTERNAL_LINK ||
                                    $ctrl.transient.target.type ===
                                        CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK)
                            );
                        },
                        isVisible: $ctrl.hasUpdateCapability,
                    },
                    {
                        label: 'Clone',
                        title: 'Make a clone',
                        action: clone,
                        enabled: () => !$ctrl.invalidTargetMessage,
                        isVisible: $ctrl.hasCreateCapability,
                    },
                    {
                        label: 'Delete',
                        title: 'Delete link',
                        hasWarning: true,
                        action: doDelete,
                        isVisible: $ctrl.hasDeleteCapability,
                    },
                ];
                if ($ctrl.transient.target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                    $ctrl.idTemplate = crossLinkDataService.getPropertyIdentifierTemplate(
                        $ctrl.transient.target
                    );
                }
            }

            function onNavigatorSelector(navigator) {
                transient.target = {
                    ...transient.target,
                    navigatorName: navigator.name,
                    type: CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK,
                    navigatorId: navigator.instanceId,
                    navigatorCode: navigator.id,
                    navigatorPropertyIdentifierTemplate: navigator.idTemplate,
                };
                $ctrl.idTemplate = crossLinkDataService.getPropertyIdentifierTemplate(
                    transient.target
                );
                $timeout(() => {
                    onTargetUpdate(transient.target);
                });
                $scope.crossLinkForm.$setDirty();
            }

            function onAddViewType(viewType) {
                $ctrl.viewType = viewType;
                transient.target.navigatorView = viewType.toUpperCase();
                if (viewType === 'Instance') {
                    $ctrl.idTemplate = crossLinkDataService.getPropertyIdentifierTemplate(
                        transient.target
                    );
                    transient.target = {
                        ...transient.target,
                        navigatorPropertyIdentifierTemplate: $ctrl.idTemplate,
                    };
                    $timeout(() => {
                        onTargetUpdate(transient.target);
                    });
                }
                $scope.crossLinkForm.$setDirty();
            }

            function getViewTypeLabel(navigatorView) {
                return $ctrl.viewTypes.find((view) => view.toUpperCase() === navigatorView);
            }

            function onAddConditionClick() {
                if ($ctrl.isAddConditionEnabled) {
                    addNewCondition();
                    $ctrl.isAddConditionEnabled = false;
                }
            }

            function addNewCondition() {
                $ctrl.conditions.push({ property: '', values: [] });
            }

            function removeCondition(index) {
                $ctrl.conditions.splice(index, 1);
                $ctrl.isAddConditionEnabled = $ctrl.conditions.length === 0;
            }

            function setConditions() {
                $ctrl.conditions = angular.copy($ctrl.target.conditions) || [];
                $ctrl.isAddConditionEnabled = $ctrl.conditions.length === 0;
            }

            function closeParentModal() {
                $scope.$emit('close modal');
            }

            function setTransientTarget() {
                transient.target = angular.copy($ctrl.target);
                transient.definition = angular.copy($ctrl.definition);
            }

            function onTargetUpdate(target, oldTarget) {
                if (!_.isEqual(target, oldTarget)) {
                    if (target.minimumTimeWindow) {
                        $ctrl.minimumTimeWindow = convertMSToString(target.minimumTimeWindow);
                        transient.target.minimumTimeWindow = target.minimumTimeWindow;
                    }

                    $ctrl.dashboardLabel = getDashboardLabel();
                    $ctrl.navigatorLabel = getNavigatorLabel();
                    $ctrl.viewLabel = getNavigatorViewLabel();

                    if (!target._isNew && !crossLinkUtils.isValidTarget(target)) {
                        $ctrl.invalidTargetMessage =
                            target.type === CROSS_LINK_TYPES.INTERNAL_LINK
                                ? 'The linked dashboard no longer exists. Please update this link.'
                                : 'Invalid link';
                        $ctrl.invalidTargetMessage =
                            target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK
                                ? 'The linked navigator no longer exists. Please update this link.'
                                : 'Invalid link';
                    } else {
                        delete $ctrl.invalidTargetMessage;
                    }
                }
            }

            function onDefinitionUpdate(definition, oldDefinition) {
                if (!_.isEqual(definition, oldDefinition)) {
                    $ctrl.triggerHeaderLabel = getTriggerHeaderLabel();
                    const triggerType = getTriggerType();

                    if (!$ctrl.editingTrigger) {
                        if (isSettableTrigger(triggerType)) {
                            setTriggerType(triggerType);
                        } else {
                            const availableTriggers =
                                linkTypes[CROSS_LINK_TYPES[transient.target.type]]
                                    .availableTriggers;
                            for (const i in availableTriggers) {
                                if (isSettableTrigger(availableTriggers[i])) {
                                    setTriggerType(availableTriggers[i]);
                                    break;
                                }
                            }
                        }
                    }

                    if ($ctrl.editingTrigger) {
                        editTrigger();
                    } else {
                        $ctrl.transientTrigger = getTrigger();
                    }
                    updateAvailableLinkLabels(transient.definition);
                }
            }

            function updateAvailableLinkLabels(trigger) {
                if (trigger) {
                    $ctrl.availableLinkLabels = $ctrl.getAvailableLinkLabels(trigger);
                }
            }

            function getTriggerHeaderLabel() {
                if (transient.definition.propertyName) {
                    return (
                        transient.definition.propertyName +
                        ':' +
                        (transient.definition.propertyValue || '*')
                    );
                } else {
                    return 'Any property';
                }
            }

            function getTriggerType() {
                if (transient.definition.propertyName) {
                    return transient.definition.propertyValue
                        ? triggerTypes.PAIR
                        : triggerTypes.ANY_VALUE;
                } else {
                    return triggerTypes.ANY_METADATA;
                }
            }

            function isSettableTrigger(trigger) {
                return (
                    trigger &&
                    triggerTypes[trigger.name] &&
                    linkTypes[CROSS_LINK_TYPES[transient.target.type]].availableTriggers.includes(
                        trigger
                    )
                );
            }

            function getRestrictedTriggerTypes({ propertyName, propertyValue }) {
                const restrictedTriggerTypes = {};
                if (propertyName && propertyValue && propertyValue !== '*') {
                    restrictedTriggerTypes.PAIR = allTriggerTypes.PAIR;
                } else if (propertyName) {
                    restrictedTriggerTypes.ANY_VALUE = allTriggerTypes.ANY_VALUE;
                } else {
                    restrictedTriggerTypes.ANY_METADATA = allTriggerTypes.ANY_METADATA;
                }

                return restrictedTriggerTypes;
            }

            function setTriggerType(trigger, focusInput) {
                if (isSettableTrigger(trigger)) {
                    if ($ctrl.triggerType !== trigger) {
                        $ctrl.triggerType = trigger;
                        markDirty('triggerType');
                    }

                    if ($ctrl.editingTrigger) {
                        $ctrl.exitEdit();
                    }

                    if (trigger === triggerTypes.PAIR) {
                        if ($ctrl.crossLinkToEdit) {
                            if (_.isEmpty(transient.definition.propertyName)) {
                                transient.definition.propertyName =
                                    $ctrl.crossLinkToEdit.propertyName;
                                transient.definition.propertyValue =
                                    $ctrl.crossLinkToEdit.propertyValue;
                            } else if (
                                _.isEmpty(transient.definition.propertyValue) &&
                                transient.definition.propertyName ===
                                    $ctrl.crossLinkToEdit.propertyName
                            ) {
                                transient.definition.propertyValue =
                                    $ctrl.crossLinkToEdit.propertyValue;
                            }
                        }

                        if (_.isEmpty(transient.definition.propertyValue)) {
                            editTrigger(focusInput, false);
                        }
                    } else if (trigger === triggerTypes.ANY_VALUE) {
                        delete transient.definition.propertyValue;
                        if (_.isEmpty(transient.definition.propertyName) && $ctrl.crossLinkToEdit) {
                            transient.definition.propertyName = $ctrl.crossLinkToEdit.propertyName;
                        }

                        if (_.isEmpty(transient.definition.propertyName)) {
                            editTrigger(focusInput, false);
                        }
                    } else if (trigger === triggerTypes.ANY_METADATA) {
                        delete transient.definition.propertyValue;
                        delete transient.definition.propertyName;
                        delete $ctrl.transientTrigger;
                        // Invalidates any existing auto-suggest queries
                        suggestionGenerationNumber++;
                    }
                }
                $ctrl.showTriggerSuggestionBox = false;
            }

            function getScope() {
                return transient.definition.contextId
                    ? crosslinkScopes.DASHBOARD
                    : crosslinkScopes.GLOBAL;
            }

            function setScope(scopeType) {
                if ($ctrl.availableCrosslinkScopes.includes(scopeType)) {
                    if (scopeType === crosslinkScopes.GLOBAL) {
                        delete transient.definition.contextId;
                    } else {
                        transient.definition.contextId = $ctrl.dashboardContext;
                    }
                    markDirty('contextId');
                }
            }

            function setTimeWindow(time) {
                if (!_.isEmpty(time) && time !== transient.target.minimumTimeWindow) {
                    $ctrl.minimumTimeWindow = time;
                    transient.target.minimumTimeWindow = convertStringToMS(time);
                    markDirty('timeWindow');
                }
            }

            function getTrigger() {
                if (!_.isEmpty(transient.definition.propertyName)) {
                    return (
                        transient.definition.propertyName +
                        (transient.definition.propertyValue
                            ? ':' + transient.definition.propertyValue
                            : '')
                    );
                }

                return '';
            }

            function editTrigger(focusInput, deletePrevious) {
                if (!$ctrl.restrictTrigger) {
                    const triggerString = getTrigger();

                    if (triggerString.includes(':')) {
                        $ctrl.transientTrigger = triggerString;
                    } else {
                        if (deletePrevious || !triggerString) {
                            $ctrl.transientTrigger = '';
                        } else {
                            $ctrl.transientTrigger = triggerString;
                            if ($ctrl.triggerType === triggerTypes.PAIR) {
                                $ctrl.transientTrigger += ':';
                            }
                        }
                    }

                    setEditingTrigger(true);

                    if (focusInput) {
                        focusTriggerInput();
                    }

                    if (focusInput || (!$ctrl.loadingSuggestions && _.isEmpty($ctrl.suggestions))) {
                        $ctrl.updateSuggestions();
                    }
                }
            }

            function onSelect(item) {
                const splitInput = $ctrl.transientTrigger.split(':');

                if ($ctrl.triggerType === triggerTypes.PAIR && splitInput.length === 2) {
                    $ctrl.transientTrigger = $ctrl.transientTrigger.replace(
                        /:.*/,
                        `:${item.value}`
                    );

                    const property = splitInput[0];
                    const value = item.value;

                    transient.definition.propertyName = property;
                    transient.definition.propertyValue = value;
                } else if ($ctrl.triggerType === triggerTypes.ANY_VALUE && splitInput.length > 0) {
                    if (splitInput.length === 1) {
                        transient.definition.propertyName = item.value;
                    } else {
                        transient.definition.propertyName = splitInput[0];
                    }
                    delete transient.definition.propertyValue;
                } else {
                    $ctrl.transientTrigger = `${item.value}:`;
                    updateSuggestions();
                    return;
                }

                updateAvailableLinkLabels(transient.definition);
                setEditingTrigger(false);
                $ctrl.showDropdown = false;
                $ctrl.suggestions = null;
            }

            function setEditingTrigger(editing) {
                $ctrl.editingTrigger = editing;
                markDirty('trigger');
            }

            function updateSuggestions() {
                const splitInput = $ctrl.transientTrigger.split(':');
                const searchProperties = splitInput.length === 1;
                const type = searchProperties ? 'property' : 'value';
                $ctrl.suggestions = [];

                if (
                    splitInput.length > 2 ||
                    (type === 'value' && $ctrl.triggerType !== triggerTypes.PAIR)
                ) {
                    $ctrl.showDropdown = false;
                    return;
                }

                const propertyName = splitInput[0];
                const propertyValue = splitInput.slice(1).join(':');
                const partialInput = searchProperties ? propertyName : propertyValue;

                suggestionGenerationNumber++;
                const localGeneration = suggestionGenerationNumber;

                $ctrl.loadingSuggestions = true;

                updateAvailableLinkLabels({ propertyName, propertyValue });

                return getSuggestions(type, partialInput, propertyName)
                    .then((suggestions) => {
                        if (localGeneration !== suggestionGenerationNumber) {
                            return Promise.reject('cancelled');
                        }

                        $ctrl.suggestions = [];
                        const typeIsProperty = type === 'property';

                        const literalInput = searchProperties ? propertyName : propertyValue;

                        if (literalInput && !suggestions.includes(literalInput)) {
                            $ctrl.suggestions.push({
                                displayName: literalInput,
                                value: literalInput,
                            });
                        }

                        if (
                            typeIsProperty &&
                            $ctrl.crossLinkToEdit &&
                            $ctrl.crossLinkToEdit.propertyName
                        ) {
                            $ctrl.suggestions.push({
                                displayName: $ctrl.crossLinkToEdit.propertyName,
                                value: $ctrl.crossLinkToEdit.propertyName,
                            });
                            _.pull(suggestions, $ctrl.crossLinkToEdit.propertyName);
                        } else if (
                            !typeIsProperty &&
                            $ctrl.crossLinkToEdit &&
                            propertyName === $ctrl.crossLinkToEdit.propertyName &&
                            $ctrl.crossLinkToEdit.propertyValue
                        ) {
                            $ctrl.suggestions.push({
                                displayName: $ctrl.crossLinkToEdit.propertyValue,
                                value: $ctrl.crossLinkToEdit.propertyValue,
                            });
                            _.pull(suggestions, $ctrl.crossLinkToEdit.propertyValue);
                        }

                        suggestions.forEach((s) =>
                            $ctrl.suggestions.push({ displayName: s, value: s })
                        );

                        $ctrl.showDropdown = $ctrl.editingTrigger;
                        $ctrl.highlighted = $ctrl.suggestions[0];
                    })
                    .catch((reason) => {
                        if (reason !== 'cancelled') {
                            $log.info('Signalflow suggest failed');
                        }
                    })
                    .finally(() => {
                        $ctrl.loadingSuggestions = false;
                    });
            }

            function getSuggestions(type, partialInput, property, limit) {
                if (type === 'value') {
                    return dimensionService.getPropertyValueSuggest({
                        property: property,
                        partialInput: partialInput || '',
                        limit,
                    });
                } else {
                    return dimensionService.getPropertyNameSuggest({
                        partialInput: partialInput || '',
                        limit,
                    });
                }
            }

            function getLinkType(type) {
                return linkTypes[CROSS_LINK_TYPES[type]].value;
            }

            function getLinkTypeLabel(type) {
                return linkTypes[CROSS_LINK_TYPES[type]].label;
            }

            function getExternalLinkUrlPlaceholder(type) {
                return type === CROSS_LINK_TYPES.KIBANA_LINK
                    ? 'https://host:port/path?_g=()&logFilter=(expression:\'{{key}} : "{{value}}" and host.name : ' +
                          '"{{properties.[host]}}" and @timestamp >= "{{start_time}}" and @timestamp <= "{{end_time}}"\',kind:kuery)'
                    : 'https://anexternalsite.com/search/?field={{key}}&value={{value}}&host={{properties.[host]}}&st=' +
                          '{{start_time}}&et={{end_time}}';
            }

            function getTotalInternalTargets() {
                return $ctrl.definition.targets.reduce(
                    (sum, target) => (sum + target.type === CROSS_LINK_TYPES.INTERNAL_LINK ? 1 : 0),
                    0
                );
            }

            function showNavigatorSelector(type) {
                return type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK;
            }

            function updateTargetType(type) {
                type = getLinkType(type);
                $ctrl.showNavigator = showNavigatorSelector(type);
                $ctrl.viewType = '';
                if (transient.target.isDefault && type !== CROSS_LINK_TYPES.INTERNAL_LINK) {
                    if (getTotalInternalTargets() > 1) {
                        throw new Error('Cannot change Link Type of a default Cross Link');
                    }
                }
                transient.target = _.extend(
                    { type, name: transient.target.name },
                    templates[type].defaults
                );
                onTargetUpdate(transient.target);
                onDefinitionUpdate(transient.definition);
                setForm(type);
                $scope.crossLinkForm.$setPristine();
                markDirty('targetType');
            }

            function setForm(type) {
                $ctrl.extendedFormTemplate = templates[getLinkType(type)].template;
            }

            function getDashboardLabel() {
                if (transient.target.dashboardName) {
                    return (
                        transient.target.dashboardName +
                        '(' +
                        transient.target.dashboardGroupName +
                        ')'
                    );
                }
                return null;
            }

            function getNavigatorLabel() {
                if (transient.target.navigatorName) {
                    return transient.target.navigatorName;
                }
                return null;
            }

            function getNavigatorViewLabel() {
                if (transient.target.navigatorView) {
                    return transient.target.navigatorView;
                }
                return null;
            }

            function selectDashboardTarget() {
                const triggerString = getTrigger();

                const modalResult = dashboardSelectorService.promptForDashboard({
                    title: `Select target dashboard for ${triggerString}`,
                    includeGenerated: true,
                    includeReadOnly: true,
                });

                modalResult.then(({ dashboard }) => {
                    angular.extend(
                        transient.target,
                        crossLinkUtils.getTargetFromDashboard(dashboard, transient.target.isDefault)
                    );
                    $ctrl.dashboardLabel = getDashboardLabel();
                    markDirty('targetDashboard');
                });
            }

            function markDirty(name) {
                if ($scope.crossLinkForm) {
                    ($scope.crossLinkForm[name] || $scope.crossLinkForm).$setDirty();
                }
            }

            function isFormValid() {
                let isValid =
                    $scope.crossLinkForm && $scope.crossLinkForm.$valid && !$ctrl.editingTrigger;

                isValid = !$ctrl.conditions.some((condition) => condition.editingTrigger);

                if (transient.target.type === CROSS_LINK_TYPES.INTERNAL_LINK) {
                    isValid = isValid && $ctrl.dashboardLabel;
                }

                if (transient.target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                    isValid = isValid && $ctrl.navigatorLabel && $ctrl.viewLabel;
                }

                return isValid;
            }

            function handleUrlUpdate(inputValue) {
                $ctrl.transient.target.url = inputValue;
                if ($scope.crossLinkForm) {
                    $scope.crossLinkForm.linkUrl.$setDirty();
                }
            }

            function insertVariable(variableName) {
                $scope.$broadcast('suggestions added', `{{${variableName}}}`);
            }

            function insertPropertyPrefix() {
                $scope.$broadcast('suggestions added', '{{properties.[');
            }

            function setTimeFormat(format) {
                $ctrl.transient.target.timeFormat = format;
                markDirty('timeFormat');
            }

            function focusTriggerInput() {
                $timeout(() => {
                    $element.find('input[name="trigger"]').focus();
                });
            }

            function cancel() {
                if ($ctrl.target._isNew) {
                    $ctrl.onDelete();
                } else {
                    setTransientTarget();
                    setConditions();
                    onTargetUpdate(transient.target);
                    onDefinitionUpdate(transient.definition);
                    setForm($ctrl.transient.target.type);
                    if ($ctrl.transient.target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                        $ctrl.idTemplate = crossLinkDataService.getPropertyIdentifierTemplate(
                            $ctrl.transient.target
                        );
                    }
                }

                $scope.crossLinkForm.$setPristine();
            }

            function save() {
                $ctrl.showRowPlaceholder = null;
                if ($ctrl.isFormValid()) {
                    $ctrl.errorResponse = null;
                    $ctrl.triggerError = false;
                    $ctrl.loading = true;

                    transient.target.conditions =
                        $ctrl.conditions.length === 0
                            ? undefined
                            : $ctrl.conditions.map((condition) => ({
                                  property: condition.property,
                                  values: condition.values,
                                  not: false,
                              }));

                    $ctrl
                        .onSave({ updates: transient })
                        .then(() => {
                            $scope.crossLinkForm.$setPristine();
                            $ctrl.rowExpanded = false;
                            if (_.isNil(transient.definition.contextId) && !$ctrl.showGlobalLinks) {
                                $ctrl.showRowPlaceholder = true;
                            } else {
                                $timeout(() => {
                                    setTransientTarget();
                                    onTargetUpdate(transient.target);
                                    onDefinitionUpdate(transient.definition);
                                });
                            }
                        })
                        .catch((errorResponse) => {
                            $ctrl.errorResponse = errorResponse.data.message;
                        })
                        .finally(() => {
                            $ctrl.loading = false;
                            $scope.$applyAsync();
                        });
                } else {
                    if ($ctrl.editingTrigger) {
                        $ctrl.triggerError = true;
                    }
                    $ctrl.conditions.forEach(
                        (condition) => (condition.hasError = condition.editingTrigger)
                    );
                }
                if ($ctrl.transient.target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                    userAnalytics.event(
                        'click',
                        'navigator-data-link-saved-click',
                        null,
                        $ctrl.transient.target?.navigatorCode ||
                            crossLinkUtils.CUSTOM_ORGANIZATION_SCOPE_ENTITY
                    );
                }
            }

            function setDefault() {
                $ctrl.errorResponse = null;
                $ctrl.loading = true;
                $ctrl
                    .onSetDefault()
                    .catch((errorResponse) => {
                        $ctrl.errorResponse = errorResponse.data.message;
                    })
                    .finally(() => ($ctrl.loading = false));
            }

            function doDelete() {
                $ctrl.errorResponse = null;
                $ctrl.loading = true;
                $ctrl
                    .onDelete()
                    .catch((errorResponse) => {
                        $ctrl.errorResponse = errorResponse.data.message;
                    })
                    .finally(() => ($ctrl.loading = false));
            }

            function clone() {
                $ctrl.rowExpanded = false;
                $ctrl.onClone(transient, ++cloneId);
            }

            function toggleRow() {
                if (!$ctrl.rowExpanded) {
                    $ctrl.rowExpanded = true;
                } else {
                    let promise = null;
                    if ($scope.crossLinkForm.$dirty) {
                        promise = confirmService.confirm({
                            title: 'Discard changes',
                            text: [
                                'You have unsaved changes. These changes will be lost if you close without saving.',
                            ],
                            yesText: 'Close',
                            noText: 'Cancel',
                        });
                    } else {
                        promise = $q.when(true);
                    }

                    return $q.when(promise).then((closeRow) => {
                        if (closeRow) {
                            cancel();
                            $ctrl.rowExpanded = false;
                        }
                    });
                }
            }

            $scope.$on('custom message updated', ($event, inputValue) =>
                handleUrlUpdate(inputValue)
            );
            $scope.$on('filter text', (ev, val) => ($ctrl.filterText = { name: val }));
            $scope.$on(CROSS_LINK_EVENTS.CROSS_LINK_UPDATED, () =>
                updateAvailableLinkLabels(transient.definition)
            );
            $scope.$watch(
                '$ctrl.target.isDefault',
                (isDefault) => ($ctrl.transient.target.isDefault = isDefault)
            );
            $scope.$watchCollection('$ctrl.transient.target', onTargetUpdate, true);
            $scope.$watchCollection('$ctrl.transient.definition', onDefinitionUpdate);
        },
    ],
};
